import React from "react";
import { Col, Container, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import NavbarComponent from "../components/Navbar";
import SideNavBarStudent from "../components/sidebarstu";
{
  /* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/
}
const Student2 = () => {
  return (
    <div>
      <SideNavBarStudent />
      <NavbarComponent />
      <Container
        fluid="sm"
        style={{
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Row
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col lg={8} className="mt-5 mb-2">
            <ListGroup>
              <ListGroupItem
                style={{ backgroundColor: "#291749", color: "white" }}
              >
                Report
              </ListGroupItem>
              <ListGroupItem>
                <img
                  src={require("../imgs/reportimage.png")}
                  alt="Report Image"
                  style={{ maxWidth: "100%" }}
                />
              </ListGroupItem>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Student2;
