import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import NavbarComponent from "../components/Navbar";
import SideNavBarStudent from "../components/sidebarstu";
import laravel from "../layouts/laravel.json";
{
  /* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/
}
const StudentCourseViewMore = () => {
  const [studentData, setStudentData] = useState({
    name: "",
    email: "",
  });
  const [exams, setExams] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const { course_id } = useParams();

  useEffect(() => {
    const storedUserData = localStorage.getItem("RCUsers");
    if (storedUserData) {
      const userData = JSON.parse(storedUserData);
      setStudentData({
        name: userData.userName,
        email: userData.email,
      });

      if (course_id) {
        fetchExams(course_id);
      }
    }
  }, [course_id]);

  const fetchExams = async (courseId) => {
    try {
      const response = await axios.post(
        `${laravel.url}fetchDataById/${courseId}`,
        {
          type: "exams",
        }
      );
      const data = response.data;

      if (!data.error) {
        setExams(data.exams);
        setAssignments(data.assignments);
      } else {
        console.error("Error fetching exams:", data.error);
      }
    } catch (error) {
      console.error("Error fetching exams:", error);
    }
  };

  const handleFileUpload = async (examId, event) => {
    const file = event.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append("examId", examId);
      formData.append("pdfFile", file);

      try {
        await axios.post(
          laravel.url + "reportcard/server/upload.php",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // You can add a success message or update the UI accordingly.
        console.log(`PDF file for exam ID ${examId} uploaded successfully.`);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  return (
    <div>
      <SideNavBarStudent />
      <NavbarComponent />
      <Container
        fluid="sm"
        style={{
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Row
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col lg={8} className="mt-5 mb-2">
            <ListGroup>
              <ListGroupItem
                style={{ backgroundColor: "#291749", color: "white" }}
              >
                Exam Details
              </ListGroupItem>
              {exams && exams.length > 0 ? (
                exams.map((exam, index) => (
                  <ListGroupItem key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>{exam.exam_name}</span>
                      <span>Grade: {exam.grade}</span>
                      <Link
                        to="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button
                          style={{
                            backgroundColor: "#291749",
                            color: "white",
                            border: "none",
                          }}
                          className="btn btn-primary"
                        >
                          View
                        </button>
                      </Link>
                      <input
                        type="file"
                        accept=".pdf"
                        onChange={(e) => handleFileUpload(exam.exam_id, e)}
                        style={{ display: "none" }}
                        id={`fileInput_${exam.exam_id}`}
                      />
                      <label
                        htmlFor={`fileInput_${exam.exam_id}`}
                        className="btn btn-secondary"
                        style={{
                          backgroundColor: "#291749",
                          color: "white",
                          border: "none",
                        }}
                      >
                        Upload PDF
                      </label>
                    </div>
                  </ListGroupItem>
                ))
              ) : (
                <ListGroupItem>
                  No exams available for this course.
                </ListGroupItem>
              )}
            </ListGroup>
          </Col>
        </Row>
      </Container>
      <Container
        fluid="sm"
        style={{
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Row
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col lg={8} className="mt-5 mb-2">
            <ListGroup>
              <ListGroupItem
                style={{ backgroundColor: "#291749", color: "white" }}
              >
                Assignment Details
              </ListGroupItem>
              {assignments && assignments.length > 0 ? (
                assignments.map((assignment, index) => (
                  <ListGroupItem key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>{assignment.assignment_name}</span>
                      <span>Grade: {assignment.grade}</span>
                      <Link
                        to="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button
                          style={{
                            backgroundColor: "#291749",
                            color: "white",
                            border: "none",
                          }}
                          className="btn btn-primary"
                        >
                          View
                        </button>
                      </Link>
                      <input
                        type="file"
                        accept=".pdf"
                        onChange={(e) =>
                          handleFileUpload(assignment.assignment_id, e)
                        }
                        style={{ display: "none" }}
                        id={`fileInput_${assignment.assignment_id}`}
                      />
                      <label
                        htmlFor={`fileInput_${assignment.assignment_id}`}
                        className="btn btn-secondary"
                        style={{
                          backgroundColor: "#291749",
                          color: "white",
                          border: "none",
                        }}
                      >
                        Upload PDF
                      </label>
                    </div>
                  </ListGroupItem>
                ))
              ) : (
                <ListGroupItem>
                  No assignments available for this course.
                </ListGroupItem>
              )}
            </ListGroup>
          </Col>
        </Row>
      </Container>
      // ... (previous code)
      <Container
        fluid="sm"
        style={{
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Row
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col lg={8} className="mt-5 mb-2">
            <ListGroup>
              <ListGroupItem
                style={{ backgroundColor: "#291749", color: "white" }}
              >
                Course Content
              </ListGroupItem>
              {/* Add the chapter links here */}
              <ListGroupItem>
                <Link
                  to="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Chapter 1
                </Link>
              </ListGroupItem>
              <ListGroupItem>
                <Link
                  to="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Chapter 2
                </Link>
              </ListGroupItem>
              <ListGroupItem>
                <Link
                  to="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Chapter 3
                </Link>
              </ListGroupItem>
              {/* ... (other items) */}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default StudentCourseViewMore;
