import { Route, Routes } from "react-router-dom";
// import './css/home.css'
import RestrictedRoutes from "./RestrictedRoutes";
import Index from "./layouts";
import AddStudentPerformance from "./layouts/AddStudentPerformance";
import { AuthProvider } from "./layouts/AuthContext";
import CoordinatorCreateProgram from "./layouts/CoordinatorCreateProgram";
import CourseLoad from "./layouts/CourseLoad";
import Curriculumn from "./layouts/Curriculum";
import Discussions from "./layouts/Discussions";
import InstructorAccount from "./layouts/InstructorAccount";
import Policies from "./layouts/Policies";
import Processes from "./layouts/Processes";
import ProgramDetail from "./layouts/ProgramDetail";
import QaAccount from "./layouts/QaAccount";
import QueriesCourses from "./layouts/Queries_Courses";
import StudentDetail from "./layouts/StudentDetail";
import StudentPerformance from "./layouts/StudentPerformance";
import StudentPerformanceDetail from "./layouts/StudentPerformanceDetail";
import About from "./layouts/about";
import Admin from "./layouts/admin";
import AdminAccount from "./layouts/adminac";
import Cooadm from "./layouts/adminco";
import { Listusers } from "./layouts/adminedit";
import Adminins from "./layouts/adminins";
import Aq from "./layouts/adminqa";
import Studentad from "./layouts/adminstudent";
import Chat from "./layouts/chat";
import Contact from "./layouts/contact";
import Coordinator from "./layouts/coordinator2";
import CoordinatorAccount from "./layouts/coordinatorAccount";
import Courses from "./layouts/courses";
import ForgotPasword from "./layouts/forgotPasword";
import Instructor from "./layouts/instructor3";
import Login from "./layouts/login";
import Logout from "./layouts/logout";
import QAOfficer from "./layouts/qa";
import QaAdmins from "./layouts/qaAdmins";
import QaCoordinators from "./layouts/qaCoordinators";
import QaInstructors from "./layouts/qaInstructors";
import QaStudents from "./layouts/qaStudents";
import Queries from "./layouts/queries";
import Register from "./layouts/register";
import Student from "./layouts/student2";
import StudentAccount from "./layouts/studentAccount";
import StudentCourseViewMore from "./layouts/studentcourseviewmore";
import Studentreport from "./layouts/studentreport";
import Chatting from "./layouts/Chatting";
import UserSearch from "./layouts/userSearch";
import MyChatbot from "./layouts/MyChatbot";
import MyChatbotStudent from "./layouts/MyChatbotStudent";
import MyChatbotIns from "./layouts/MyChatbotIns";
import MyChatbotCO from "./layouts/MyChatbotCO";
function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<RestrictedRoutes element={<Index />} />} />
        <Route
          path="/about-us"
          element={<RestrictedRoutes element={<About />} />}
        />
        <Route
          path="/contact-us"
          element={<RestrictedRoutes element={<Contact />} />}
        />
        <Route
          path="/login"
          element={<RestrictedRoutes element={<Login />} />}
        />
        <Route
          path="/register"
          element={<RestrictedRoutes element={<Register />} />}
        />
        <Route
          path="/courses"
          element={<RestrictedRoutes element={<Courses />} />}
        />
        <Route
          path="/queries"
          element={<RestrictedRoutes element={<Queries />} />}
        />

        <Route
          path="/forgort-password"
          element={<RestrictedRoutes element={<ForgotPasword />} />}
        />
        <Route
          path="/admin"
          element={<RestrictedRoutes element={<Admin />} />}
        />
        <Route
          path="/student"
          element={<RestrictedRoutes element={<Student />} />}
        />
        <Route
          path="/student-account"
          element={<RestrictedRoutes element={<StudentAccount />} />}
        />
        <Route
          path="/student-detail/:id?"
          element={<RestrictedRoutes element={<StudentDetail />} />}
        />
        <Route
          path="/student-performance-detail/:id?"
          element={<RestrictedRoutes element={<StudentPerformanceDetail />} />}
        />
        <Route
          path="/student-performance"
          element={<RestrictedRoutes element={<StudentPerformance />} />}
        />

        <Route
          path="/add-student-performance"
          element={<RestrictedRoutes element={<AddStudentPerformance />} />}
        />
        <Route
          path="/qa-officer"
          element={<RestrictedRoutes element={<QAOfficer />} />}
        />
        <Route
          path="/instructor"
          element={<RestrictedRoutes element={<Instructor />} />}
        />
        <Route
          path="/instructors"
          element={<RestrictedRoutes element={<CourseLoad />} />}
        />
        <Route
          path="/instructor-account"
          element={<RestrictedRoutes element={<InstructorAccount />} />}
        />
        <Route
          path="/logout"
          element={<RestrictedRoutes element={<Logout />} />}
        />
        <Route path="/chat" element={<RestrictedRoutes element={<Chat />} />} />
        <Route
          path="/discussions"
          element={<RestrictedRoutes element={<Discussions />} />}
        />
        <Route
          path="/coordinator"
          element={<RestrictedRoutes element={<Coordinator />} />}
        />
        <Route
          path="/coordinator-account"
          element={<RestrictedRoutes element={<CoordinatorAccount />} />}
        />

        <Route
          path="/create-program"
          element={<RestrictedRoutes element={<CoordinatorCreateProgram />} />}
        />
        <Route
          path="/program-detail/:id?"
          element={<RestrictedRoutes element={<ProgramDetail />} />}
        />
        <Route
          path="/adminac"
          element={<RestrictedRoutes element={<AdminAccount />} />}
        />
        <Route
          path="/adminin"
          element={<RestrictedRoutes element={<Adminins />} />}
        />
        <Route
          path="/studentadmin"
          element={<RestrictedRoutes element={<Studentad />} />}
        />
        <Route
          path="/officad"
          element={<RestrictedRoutes element={<Aq />} />}
        />
        <Route
          path="/cordad"
          element={<RestrictedRoutes element={<Cooadm />} />}
        />
        <Route
          path="/edit/:id"
          element={<RestrictedRoutes element={<Listusers />} />}
        />

        <Route
          path="/qa-account"
          element={<RestrictedRoutes element={<QaAccount />} />}
        />
        <Route
          path="/Curriculum"
          element={<RestrictedRoutes element={<Curriculumn />} />}
        />
        <Route
          path="/queries_courses"
          element={<RestrictedRoutes element={<QueriesCourses />} />}
        />
        <Route
          path="/qaStudents"
          element={<RestrictedRoutes element={<QaStudents />} />}
        />
        <Route
          path="/qaAdmins"
          element={<RestrictedRoutes element={<QaAdmins />} />}
        />
        <Route
          path="/qaInstructors"
          element={<RestrictedRoutes element={<QaInstructors />} />}
        />
        <Route
          path="/qaCoordinators"
          element={<RestrictedRoutes element={<QaCoordinators />} />}
        />
        <Route
          path="/policies"
          element={<RestrictedRoutes element={<Policies />} />}
        />
        <Route
          path="/processes"
          element={<RestrictedRoutes element={<Processes />} />}
        />

        <Route
          path="/viewmore/:course_id"
          element={<RestrictedRoutes element={<StudentCourseViewMore />} />}
        />
        <Route
          path="/report"
          element={<RestrictedRoutes element={<Studentreport />} />}
        />
        <Route
          path="/chatting"
          element={<RestrictedRoutes element={<UserSearch />}/>}
        />
        <Route 
          path='/chata' 
          element={<RestrictedRoutes element={<MyChatbot />} /> }
        />
        <Route 
          path='/chatwithus' 
          element={<RestrictedRoutes element={<MyChatbotStudent />} /> }
        />
        
        <Route 
          path='/chatI' 
          element={<RestrictedRoutes element={<MyChatbotIns />} /> }
        />
        <Route 
          path='/chatco' 
          element={<RestrictedRoutes element={<MyChatbotCO />} /> }
        />
      </Routes>
    </AuthProvider>
  );
}

export default App;
