// SidebarNav.js

import React, { useState } from "react";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import SideNav, {
  NavIcon,
  NavItem,
  NavText
} from "@trendmicro/react-sidenav";

/* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/

const SidebarNav = ({ role }) => {
  const [visible, setVisible] = useState(true);

  return (
    <SideNav
      expanded={visible}
      style={{
        backgroundColor: "#291749",
        zIndex: "100",
        top: "2.9rem",
        height: "100%",
        position: "fixed"
      }}
    >
      <SideNav.Toggle onClick={() => setVisible(!visible)} />
      <SideNav.Nav defaultSelected="home">
        <NavItem eventKey="home">
          <NavIcon>
            <i className="fa fa-fw fa-home" style={{ fontSize: "1.75em" }} />
          </NavIcon>
          <NavText>My Profile</NavText>
        </NavItem>
        {role === "Coordinator" && (
          <NavItem eventKey="coordinator">
            <NavIcon>
              <i className="fa fa-fw fa-user" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>Coordinator</NavText>
          </NavItem>
        )}
        {role === "Student" && (
          <NavItem eventKey="student">
            <NavIcon>
              <i className="fa fa-fw fa-graduation-cap" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>Student</NavText>
          </NavItem>
        )}
        {role === "Instructor" && (
          <NavItem eventKey="instructor">
            <NavIcon>
              <i className="fa fa-fw fa-chalkboard-teacher" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>Instructor</NavText>
          </NavItem>
        )}
        {role === "QA-Officer" && (
          <NavItem eventKey="qaofficer">
            <NavIcon>
              <i className="fa fa-fw fa-shield-alt" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>QA-Officer</NavText>
          </NavItem>
        )}
        {role === "Admin" && (
          <NavItem eventKey="admin">
            <NavIcon>
              <i className="fa fa-fw fa-cogs" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>Admin</NavText>
          </NavItem>
        )}
        <NavItem eventKey="reports">
          <NavIcon>
            <i className="fa fa-fw fa-line-chart" style={{ fontSize: "1.75em" }} />
          </NavIcon>
          <NavText>Reports</NavText>
        </NavItem>
      </SideNav.Nav>
    </SideNav>
  );
};

export default SidebarNav;
