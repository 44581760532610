import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import NavbarComponent from "../components/Navbar";
import laravel from "./laravel.json";
{
  /* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/
}
const QaStudents = () => {
  const [students, setStudents] = useState([]);

  useEffect(() => {
    // Fetch data from the PHP API for the "students" table
    fetch(laravel.url + "getByRole/student")
      .then((response) => response.json())
      .then((data) => setStudents(data))
      .catch((error) => console.error("Error:", error));
  }, []);

  return (
    <div
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      <NavbarComponent />

      <div style={{ backgroundColor: "#291749", flex: "1" }}>
        <div style={{ height: "55px" }}></div>

        <Container fluid="sm" className="mt-5" style={{}}>
          <h1
            className="text-white text-center m-5"
            style={{ marginTop: "10rem" }}
          >
            Student Data
          </h1>

          <Table striped bordered hover size="sm" responsive>
            <thead>
              <tr>
                <th>id</th>
                <th>UserName</th>
                <th>email</th>
                <th>role</th>
                {/* Add more table headers for additional student data */}
              </tr>
            </thead>
            <tbody>
              {students.map((student, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{student.userName}</td>
                  <td>{student.email}</td>
                  <td>{student.role}</td>
                  {/* Add more table cells for additional student data */}
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
      </div>

      {/* Footer code */}
      <div
        style={{
          textAlign: "center",
          fontSize: "10px",
          backgroundColor: "#f5f5f5",
        }}
        className="fixed-footer"
      >
        @ 2023 SKILLIFY, INC
      </div>
    </div>
  );
};

export default QaStudents;
