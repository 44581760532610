import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import React, { useState } from "react";

/* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/

import SideNav, { NavIcon, NavItem, NavText } from "@trendmicro/react-sidenav";
import { Link } from "react-router-dom";

const getFromStorage = () => {
  const data = localStorage.getItem("RCUsers");
  if (data !== null) {
    return JSON.parse(data);
  }
  return [];
};

const SidebarNav = () => {
  const [visible, setVisible] = useState(true);
  if (getFromStorage()[0].role.value === "Coordinator") {
    return (
      <SideNav
        expanded={visible}
        style={{
          backgroundColor: "#291749",
          zIndex: "100",
          top: "2.9rem",
          height: "100%",
          position: "fixed",
        }}
      >
        <SideNav.Toggle onClick={() => setVisible(!visible)} />
        <SideNav.Nav defaultSelected="home">
          <NavItem eventKey="home">
            <NavIcon>
              <i className="fa fa-fw fa-home" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>My Profile</NavText>
          </NavItem>
          <NavItem eventKey="home">
            <NavIcon>
              <i className="fa fa-fw fa-home" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>
              <Link to="coodinator">Coordinator</Link>
            </NavText>
          </NavItem>
        </SideNav.Nav>
      </SideNav>
    );
  } else if (getFromStorage()[0].role.value === "Student") {
    return (
      <SideNav
        expanded={visible}
        style={{
          backgroundColor: "#291749",
          zIndex: "100",
          top: "2.9rem",
          height: "100%",
          position: "fixed",
        }}
      >
        <SideNav.Toggle onClick={() => setVisible(!visible)} />
        <SideNav.Nav defaultSelected="home">
          <NavItem eventKey="home">
            <NavIcon>
              <i className="fa fa-fw fa-home" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>My Profile</NavText>
          </NavItem>
          <NavItem eventKey="home">
            <NavIcon>
              <i className="fa fa-fw fa-home" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>
              <Link to="'students">Student</Link>
            </NavText>
          </NavItem>
        </SideNav.Nav>
      </SideNav>
    );
  } else if (getFromStorage()[0].role.value === "Instructor") {
    return (
      <SideNav
        expanded={visible}
        style={{
          backgroundColor: "#291749",
          zIndex: "100",
          top: "2.9rem",
          height: "100%",
          position: "fixed",
        }}
      >
        <SideNav.Toggle onClick={() => setVisible(!visible)} />
        <SideNav.Nav defaultSelected="home">
          <NavItem eventKey="home">
            <NavIcon>
              <i className="fa fa-fw fa-home" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>My Profile</NavText>
          </NavItem>
          <NavItem eventKey="home">
            <NavIcon>
              <i className="fa fa-fw fa-home" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>
              <Link to="/instructor">Instructor</Link>
            </NavText>
          </NavItem>
        </SideNav.Nav>
      </SideNav>
    );
  }

  return (
    <SideNav
      expanded={visible}
      style={{
        backgroundColor: "#291749",
        zIndex: "100",
        top: "2.9rem",
        height: "100%",
        position: "fixed",
      }}
    >
      <SideNav.Toggle onClick={() => setVisible(!visible)} />
      <SideNav.Nav defaultSelected="home">
        <NavItem eventKey="qaofficer">
          <NavIcon>
            <i className="fa fa-fw fa-home" style={{ fontSize: "1.75em" }} />
          </NavIcon>
          <NavText>
            <Link to="/qa-officer">QA-Officer</Link>
          </NavText>
        </NavItem>

        <NavItem eventKey="reports">
          <NavIcon>
            <i
              className="fa fa-fw fa-line-chart"
              style={{ fontSize: "1.75em" }}
            />
          </NavIcon>
          <NavText>Reports</NavText>
        </NavItem>
      </SideNav.Nav>
    </SideNav>
  );
};

export default SidebarNav;
