import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import React from "react";
import { Link } from "react-router-dom";

/* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/

import SideNav, { NavIcon, NavItem, NavText } from "@trendmicro/react-sidenav";

class SideNavBar2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true,
    };
  }

  render() {
    return (
      <SideNav
        expanded={this.state.isVisible}
        style={{
          backgroundColor: "#291749",
          zIndex: "100",
          top: "2.9rem",
          height: "100%",
          position: "fixed",
          marginTop: "20px",
        }}
      >
        <SideNav.Toggle
          onClick={() => {
            this.setState({ isVisible: !this.state.isVisible });
          }}
        />
        <SideNav.Nav defaultSelected="home">
          <NavItem eventKey="home">
            {this.state.isVisible ? (
              <NavText
                style={{ fontSize: "1.5em", margin: "0px 0px 20px 20px" }}
              >
                Program Coordinator
              </NavText>
            ) : null}
            {/* <NavText style={{ fontSize: '1.5em', margin: '0px 0px 20px 20px' }}>Program Coordinator</NavText> */}
          </NavItem>
          <NavItem eventKey="home">
            <NavIcon>
              <i className="fa fa-fw fa-home" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>
              <Link to="/coordinator">Dashboard</Link>
            </NavText>
          </NavItem>

          <NavItem eventKey="account">
            <NavIcon>
              <i className="fa fa-fw fa-user" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>
              <Link to="/coordinator-account">Account</Link>
            </NavText>
          </NavItem>
          <NavItem eventKey="chatting">
            <NavIcon>
              <i className="fa fa-fw fa-comment" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>
              <Link to="/chatting">Chatting</Link>
            </NavText>
          </NavItem>
          <NavItem eventKey="chatco">
            <NavIcon>
              <i className="fa fa-fw fa-comment" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>
              <Link to="/chatco">ChatBot</Link>
            </NavText>
          </NavItem>
          <NavItem eventKey="students">
            <NavIcon>
              <i className="fa fa-fw fa-users" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>
              <Link to="/courses">Programs</Link>
            </NavText>
          </NavItem>

          <NavItem eventKey="instructor">
            <NavIcon>
              <i
                className="fa fa-fw fa-calendar"
                style={{ fontSize: "1.75em" }}
              />
            </NavIcon>
            <NavText>
              <Link to="/Queries">Queries</Link>
            </NavText>
          </NavItem>

          <NavItem eventKey="coordinator">
            <NavIcon>
              <i
                className="fa fa-fw fa-comments"
                style={{ fontSize: "1.75em" }}
              />
            </NavIcon>
            <NavText>
              <Link to="/discussions">Discussion Forum</Link>
            </NavText>
          </NavItem>

          <NavItem eventKey="logout">
            <NavIcon>
              <i
                className="fa fa-fw fa-sign-out"
                style={{ fontSize: "1.75em" }}
              />
            </NavIcon>
            <NavText>
              <Link to="/logout">Logout</Link>
            </NavText>
          </NavItem>
        </SideNav.Nav>
      </SideNav>
    );
  }
}

export default SideNavBar2;
