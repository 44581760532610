import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import laravel from "../layouts/laravel.json";

/* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/
const registerURL = laravel.url + "register";
const deleteUrl = laravel.url + "deleteStudent";
const editUrl = laravel.url + "editStudent";
const loginURL = laravel.url + "login";

const initialState = {
  data: [],
  status: "idle",
  loading: false,
  error: null,
  response: "",
};

export const fetchUsers = createAsyncThunk("data/fetchUsers", async () => {
  const url = laravel.url + "retrieveStudents";
  const response = await axios.get(url);
  return response.data;
});

export const registerUser2 = createAsyncThunk(
  "data/registerUser",
  async (formData) => {
    const response = await axios.post(registerURL, formData);
    localStorage.setItem("RCUsers", JSON.stringify(response.data.userDetails));
    console.log(response.data);
    return response.data;
  }
);

export const verifyOTP = createAsyncThunk(
  "data/registerUser",
  async (formData) => {
    const response = await axios.post(laravel.url + "verifyOTP", formData);
    localStorage.setItem("RCUsers", JSON.stringify(response.data.userDetails));
    console.log(response.data);
    return response.data;
  }
);

export const loginUser = createAsyncThunk(
  "data/loginUser",
  async (formData) => {
    const response = await axios.post(loginURL, formData);
    if (response.data.status === "success") {
      localStorage.setItem(
        "RCUsers",
        JSON.stringify(response.data.userDetails)
      );
      return response.data;
    } else {
      return false;
    }
  }
);

export const deleteUser = createAsyncThunk("data/deleteUser", async (id) => {
  const response = await axios.delete(`${deleteUrl}/${id}`);
  return response.data;
});

export const editUser = createAsyncThunk("data/editUser", async (formData) => {
  const data = new FormData(formData);
  const id = data.get("id");
  const response = await axios.put(`${editUrl}/${id}`, formData);
  return response.data;
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginUser: {
      reducer(state, action) {
        state.push(action.payload);
      },
      prepare(inputValues) {
        return {
          payload: inputValues,
        };
      },
    },
    getAllUsers: {
      reducer(state, action) {
        state.push(action.payload);
      },
    },
    registerUser: {
      async reducer(state, action) {
        // convert to formdata
        const formData = new FormData();
        for (const key in action.payload) {
          formData.append(key, action.payload[key]);
        }
        await axios
          .post(registerURL, formData)
          .then((response) => {
            console.log(response);
            state.push(response.data);
          })
          .catch((err) => console.log(err));
        return action.payload;
      },
      prepare(inputValues) {
        return {
          payload: inputValues,
        };
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser2.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerUser2.fulfilled, (state, action) => {
        state.loading = false;
        state.data.push(action.payload);
        state.response = "registered";
      })
      .addCase(registerUser2.rejected, (state, action) => {
        state.error = action.error.message;
      });
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    builder.addCase(editUser.fulfilled, (state, action) => {
      const updateItem = action.payload;
      const index = state.data.findIndex((item) => item.id === updateItem.id);
      if (index !== -1) {
        state.data[index] = updateItem;
      }
      state.response = "update";
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.data = state.data.filter((item) => item.id !== action.payload);
    });

    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.data.push(action.payload);
        state.response = "login";
      })
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});

export const selecteUser = (state) => state;

export const { registerUser } = userSlice.actions;

export default userSlice.reducer;
