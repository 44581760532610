import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import laravel from "../layouts/laravel.json";

import NavbarComponent from "../components/Navbar";
import SideNavBar3 from "../components/sidebaradmin";
{
  /* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/
}

const Listusers = () => {
  const navigate = useNavigate();

  const [inputs, setInputs] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    getUser();
  }, []);

  function getUser() {
    axios.get(laravel.url + `getUserById/${id}`).then(function (response) {
      console.log(response.data);
      setInputs(response.data);
    });
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post(
        laravel.url + `updateUserData/${id}`,
        {
          id: inputs.id,
          name: inputs.userName,
          email: inputs.email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        console.log(response.data);
        // Handle the server's response here
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  return (
    <div>
      <SideNavBar3 />
      <div style={{ zIndex: "-50" }}>
        <NavbarComponent />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "80vh",
        }}
      >
        <div
          style={{
            padding: "100px",
            backgroundColor: "white",
            border: "1px solid #ccc",
            borderRadius: "5px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h1 style={{ textAlign: "center" }}>Edit user</h1>
          <form onSubmit={handleSubmit}>
            <table cellSpacing="30">
              <tbody>
                <tr style={{ marginBottom: "50px" }}>
                  <th>
                    <label>Name: </label>
                  </th>
                  <td style={{ padding: "10px" }}>
                    <input
                      value={inputs.userName}
                      type="text"
                      name="userName"
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr style={{ marginBottom: "50px" }}>
                  <th>
                    <label>Email: </label>
                  </th>
                  <td style={{ padding: "10px" }}>
                    <input
                      value={inputs.email}
                      type="text"
                      name="email"
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr style={{ marginBottom: "50px" }}>
                  <th>
                    <label>Role: </label>
                  </th>
                  <td style={{ padding: "10px" }}>{inputs.role}</td>
                </tr>
                <tr>
                  <td colSpan="2" align="center">
                    <button
                      style={{
                        backgroundColor: "#291749",
                        color: "white",
                        borderRadius: "5px",
                        width: "80px",
                        height: "30px",
                      }}
                    >
                      Save
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </div>
  );
};

export { Listusers };
