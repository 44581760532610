import React from "react";
import { Container } from "react-bootstrap";
import NavbarComponent from "../components/Navbar";
{
  /* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/
}

const styles = {
  backgroundColor: "#291749",
  /* height: 40%; */
  marginTop: "5rem",
  color: "white",
};

const Policies = () => {
  return (
    <>
      <NavbarComponent />
      {/* <SideNavBar /> */}

      <Container style={styles}>
        <div className="content-container ">
          <h1>POLICIES</h1>
          {/* <h4>SUMMARY</h4> */}
          <p>
            We are dedicated to upholding the highest academic standards and
            consistently enhancing our educational programs.
          </p>
          <p>
            We have established a systematic approach to developing and
            improving our curriculum, aligning it with educational objectives
            and industry best practices.
          </p>
          <p>
            We are committed to maintaining accreditation and complying with all
            relevant regulatory standards to ensure the quality of our
            educational offerings.
          </p>
          <p>
            We collaborate with our educators to create reliable assessment
            methods that accurately measure student learning and growth.
          </p>
          <p>
            We maintain open lines of communication with our educators and
            administrators, keeping them informed about our quality assurance
            initiatives, objectives, and results.
          </p>
        </div>
      </Container>

      {/* Footer code */}
      <div
        style={{ textAlign: "center", fontSize: "10px" }}
        className="fixed-footer"
      >
        @ 2023 SKILLIFY, INC
      </div>
    </>
  );
};

export default Policies;
