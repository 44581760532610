import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import NavbarComponent from "../components/Navbar";
import SideNavBarCoordinator from "../components/sidebarCordinator";
import laravel from "../layouts/laravel.json";
{
  /* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/
}
const Coordinator = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    // Fetch course data from the PHP file
    fetch(laravel.url + "getCourses")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setCourses(data);
      })
      .catch((error) => {
        console.error("Error fetching course data:", error);
      });
  }, []);

  const listData = [
    {
      id: 1,
      text: "Create Program",
      link: "/create-program",
    },
    {
      id: 2,
      text: "View Programs",
      link: "/courses",
    },
    {
      id: 4,
      text: "Enquiries",
      link: "/queries",
    },
  ];

  const navigate = useNavigate();

  // Function to handle course deletion
  const handleDelete = (courseId) => {
    // Make an API call to the PHP file to delete the course
    fetch(laravel.url + "deleteCourse", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ courseId }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // Refresh the course list after successful deletion
        return fetch(laravel.url + "getCourses");
      })
      .then((response) => response.json())
      .then((data) => {
        setCourses(data);
      })
      .catch((error) => {
        console.error("Error deleting course:", error);
      });
  };

  return (
    <>
      <NavbarComponent />
      <SideNavBarCoordinator />
      <Container
        style={{
          padding: "80px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        fluid="sm"
      >
        <div
          style={{
            width: "80%",
          }}
        >
          <Row lg={7} style={{ marginTop: "3rem", marginBottom: "5rem" }}>
            {listData.map((item) => (
              <Col style={{ textAlign: "center" }} key={item.id}>
                <Button
                  style={{
                    backgroundColor: "#291749",
                    border: "0",
                  }}
                >
                  <Link to={item.link} style={{ color: "white" }}>
                    {item.text}
                  </Link>
                </Button>
              </Col>
            ))}
          </Row>
          {courses.map((course) => (
            <Row className="mt-4" key={course.id}>
              <Col lg={8} md={4}>
                <Button
                  style={{
                    textAlign: "left",
                    backgroundColor: "#291749",
                    border: "0",
                    width: "100%",
                  }}
                >
                  {course.courseTitle}
                </Button>
              </Col>
              <Col style={{ textAlign: "center" }}>
                <Button
                  onClick={() => navigate(`/program-detail/${course.id}`)}
                  variant="outline-primary"
                >
                  View Content
                </Button>
              </Col>
              <Col>
                <Button
                  variant="danger"
                  onClick={() => handleDelete(course.id)}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          ))}
        </div>
      </Container>
    </>
  );
};

export default Coordinator;
