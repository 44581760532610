import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
{
  /* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/
}
const Logout = () => {
  const navigate = useNavigate();

  // Function to handle logout
  const handleLogout = () => {
    // Remove the sessionID from localStorage
    localStorage.removeItem("RCUsers");
  };

  useEffect(() => {
    // Call the handleLogout function
    handleLogout();

    // Use the cleanup function to navigate after the component is unmounted
    const timeoutId = setTimeout(() => {
      // Redirect to the login page after the delay
      navigate("/login");
    }, 1000);

    // Clear the timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, [navigate]);

  return (
    <div>
      Redirecting...
      {/* You can add additional content or a loading spinner here */}
    </div>
  );
};

export default Logout;
