import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import NavbarComponent from "../components/Navbar";
import laravel from "./laravel.json";
{
  /* 


Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/
}
const Queries_Courses = () => {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    // Fetch data from the PHP API for the "contactus" table
    fetch(laravel.url + "getCourses")
      .then((response) => response.json())
      .then((data) => setContacts(data))
      .catch((error) => console.error("Error:", error));
  }, []);

  return (
    <div
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      <NavbarComponent />

      <div style={{ backgroundColor: "#291749", flex: "1" }}>
        <div style={{ height: "55px" }}></div>

        <Container fluid="sm" className="mt-5" style={{}}>
          <h1
            className="text-white text-center m-5"
            style={{ marginTop: "10rem" }}
          >
            Courses
          </h1>

          <Table striped bordered hover size="sm" responsive>
            <thead>
              <tr>
                <th>id</th>
                <th>courseNumber</th>
                <th>courseTitle</th>
                <th>credits</th>
                <th>courseType</th>
                <th>instructor_id</th>
                <th>student_id</th>
                <th>assignment</th>
                <th>analytics</th>
              </tr>
            </thead>
            <tbody>
              {contacts.map((contact, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{contact.courseNumber}</td>
                  <td>{contact.courseTitle}</td>
                  <td>{contact.credits}</td>
                  <td>{contact.courseType}</td>
                  <td>{contact.instructor_id}</td>
                  <td>{contact.student_id}</td>
                  <td>{contact.assignment}</td>
                  <td>{contact.analytics}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
      </div>

      {/* Footer code */}
      <div
        style={{
          textAlign: "center",
          fontSize: "10px",
          backgroundColor: "#f5f5f5",
        }}
        className="fixed-footer"
      >
        @ 2023 SKILLIFY, INC
      </div>
    </div>
  );
};

export default Queries_Courses;
