import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NavbarComponent from "../components/Navbar";
import SideNavBarInst from "../components/sidebarinst";
import laravel from "../layouts/laravel.json";
{
  /* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/
}
const CourseLoad = () => {
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();

  const handleViewMore = (course) => {
    localStorage.setItem("selectedCourse", JSON.stringify(course));
    navigate("/instructor");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const instructorId = JSON.parse(localStorage.getItem("RCUsers")).id;
        console.log(instructorId);

        const response = await fetch(
          laravel.url + `getCourses/instructor_id/${instructorId}`
        );
        const data = await response.json();

        setCourses(data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {/* Your existing code */}
      <NavbarComponent />
      <SideNavBarInst />
      <Container fluid="sm" style={{ padding: "60px" }}>
        <Row>
          {courses.map((course) => (
            <Col
              key={course.id}
              lg={4}
              className="m-2"
              style={{
                backgroundColor: "#291749",
                height: "250px",
                borderRadius: "10px",
                padding: "10px",
                color: "white",
                position: "relative",
              }}
            >
              <h3 style={{ color: "white" }}>{course.courseNumber}</h3>
              <p style={{ fontSize: "14px" }}>{course.courseTitle}</p>
              <Button
                onClick={() => handleViewMore(course)}
                style={{ position: "absolute", bottom: "10px", right: "10px" }}
              >
                View more
              </Button>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default CourseLoad;
