// src/components/UserSearch.jsx

import React, { useState } from 'react';
import axios from 'axios';
import laravel from "../layouts/laravel.json";
import Chatting from './Chatting';

const UserSearch = () => {
    const [term, setTerm] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    
    const searchUsers = async () => {
        try {
            const response = await axios.get(
                laravel.url+ `search?term=${term}`
            );

            setUsers(response.data.data);
        } catch (error) {
            console.error('Error searching users:', error);
        }
    };
    const handleChatClick = (user) => {
        setSelectedUser(user);
    };

    return (
        <div>
            <input
                type="text"
                value={term}
                onChange={(e) => setTerm(e.target.value)}
                placeholder="Search users..."
            />
            <button onClick={searchUsers}>Search</button>

            {users.map((user) => (
                <div key={user.id} className="user-container">
                    <div>
                        {user.name} ({user.email})
                    </div>
                    <button onClick={() => handleChatClick(user)}>Chat</button>
                </div>
            ))}

            {selectedUser && <Chatting user={selectedUser} onClose={() => setSelectedUser(null)} />}
        </div>
    );
};

export default UserSearch;
