import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NavbarComponent from "../components/Navbar";
import ChatComponent from "./ChatComponent";
{
  /* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/
}

const Discussions = () => {
  const data = JSON.parse(localStorage.getItem("RCUsers"));
  const navigate = useNavigate();

  useEffect(() => {
    if (!!data) {
    } else {
      navigate("/");
    }
  }, [data, navigate]);
  return (
    data && (
      <div>
        <NavbarComponent />
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ChatComponent />
        </Container>
      </div>
    )
  );
};

export default Discussions;
