import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import NavbarComponent from "../components/Navbar";
import SideNavBar3 from "../components/sidebaradmin";

import { Link } from "react-router-dom";
import laravel from "../layouts/laravel.json";
{
  /* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/
}
const Cooadm = () => {
  const [users1, setUsers] = useState([]);
  useEffect(() => {
    getUsers();
  }, []);

  function getUsers() {
    axios.get(laravel.url + "getByRole/Coordinator").then(function (response) {
      setUsers(response.data);
    });
  }
  const deleteUser = (id) => {
    console.log("Deleting user with ID:", id);

    axios
      .delete(laravel.url + `delete/${id}`)
      .then(function (response) {
        console.log(response.data);
        // If deletion is successful, update the users list
        getUsers();
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <div>
      <SideNavBar3 />
      <div style={{ zIndex: "-50" }}>
        <NavbarComponent />
      </div>

      <div>
        <Container fluid="sm" className="mt-5" style={{}}>
          <div
            style={{
              marginTop: "10rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1>Coordinators</h1>
          </div>

          <Table striped bordered hover size="sm" responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Username</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {users1.map((user, key) => (
                <tr key={key}>
                  <td>{user.id}</td>
                  <td>{user.userName}</td>
                  <td>{user.email}</td>
                  <td>
                    <Link
                      to={`/edit/${user.id}`}
                      style={{
                        display: "inline-block",
                        width: "80px",

                        backgroundColor: "#291749",
                        color: "white",
                        textDecoration: "none",
                        border: "1px solid #291749",
                        borderRadius: "5px",
                        textAlign: "center",
                      }}
                    >
                      Edit
                    </Link>
                  </td>
                  <td>
                    <button
                      style={{
                        backgroundColor: "#291749",
                        color: "white",
                        width: "80px",
                        height: "25px",
                        borderRadius: "5px",
                      }}
                      onClick={() => deleteUser(user.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Container>
      </div>

      {/* Footer code */}
    </div>
  );
};

export default Cooadm;
