import React, { Component } from 'react';
import ChatBot  from 'react-simple-chatbot';
import '../css/Chatbot.css'
import SideNavBar3 from "../components/sidebaradmin";
class MyChatbot extends Component {

  render() {
    console.log('MyChatbot component rendered');
    const steps = [
      {
        id: 'Greet',
        message: 'Hi, Admin How are you?',
        trigger: 'inputans'
      },
      {
        id: 'inputans',
        user: true,
        trigger: 'AskName'
      },
      {
        id: 'AskName',
        message: 'What is your name?',
        trigger: 'input name'
      },
      {
        id: 'input name',
        user: true,
        trigger: '1'
      },
      {
        id: '1',
        message: 'Hi {previousValue}, Please Select the issue',
        trigger: '2'
      },
      {
      id:'2',
      options:[
        {value:'Courses',label:'Course',trigger:'3'},
        {value:'Programs',label:'Programs',trigger:'3'},
        {value:'Manging User Account',label:'User Account',trigger:'4'},
        {value:'Reports & Analytics',label:'Reports & Analytics',trigger:'5'},
        {value:'Website Setting & Design',label:'Setting & Design',trigger:'6'},
        {value:'CRUD Functionality',label:'CRUD',trigger:'7'}
      ]
    },{
      id:'3',
      options:[{
        value:'How do I manage Courses and Programs on the Platform',label:'How do I manage Courses and Programs on the Platform',trigger:'8'
      }]
    },{
      id:'4',
      options:[{
        value:'What tools are available for managing user accounts?',label:'What tools are available for managing user accounts?',trigger:'9'
      }]
    
    },{
      id:'5',
      options:[{
        value:'How do I generate reports and analytics?',label:'How do I generate reports and analytics?',trigger:'10'
      }]
    
    },{
      id:'6',
      options:[{
        value:'What can I do in Website Settings and Design?',label:'What can I do in Website Settings and Design?',trigger:'11'
      }]
    
    },{
      id:'7',
      options:[{
        value:'How does the CRUD functionality work?',label:'How does the CRUD functionality work?',trigger:'12'
      }]
    
    },{
      id:'8',
      message:'Use the ‘Course and Program Management’ feature to oversee course offerings, program structures, and to coordinate with departments on curriculum matters.',
      trigger:'13'
    },{
      id:'9',
      message:'The ‘User Account Management’ function allows you to create, modify, and deactivate user accounts, manage permissions, and handle account inquiries..',
      trigger:'13'
    },{
      id:'10',
      message:'Reports and analytics on student performance, course enrollment, and program effectiveness can be generated through the dedicated analytics section.',
      trigger:'13'
    },{
      id:'11',
      message:' This feature allows you to customize the look and feel of the platform, update layouts, and manage site-wide settings and notifications.',
      trigger:'13'
    },{
      id:'12',
      message:'CRUD (Create, Read, Update, Delete) functionality is integral to managing content on the platform, including student records, course materials, and program details.',
      trigger:'13'
    },{
      id:'13',
    message:'Good to help You',
    end:true
    },];

    return (
      <div>
        <SideNavBar3 />
      <ChatBot
        steps={steps}
        floating={true} // Adjust based on your UI preference
        opened={false}   // Chatbot is opened by default
      />
      </div>
    );
  }
}

export default MyChatbot;