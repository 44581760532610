import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NavbarComponent from "../components/Navbar";
import SideNavBar from "../components/sidebarqa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import laravel from "../layouts/laravel.json";
{
  /* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/
}
const QaAccount = () => {
  // const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [editedName, setEditedName] = useState("");
  const [editedEmail, setEditedEmail] = useState("");

  useEffect(() => {
    const fetchDataFromServer = async () => {
      try {
        const userId = JSON.parse(localStorage.getItem("RCUsers")).id;
        const response = await fetch(laravel.url + `getUserById/${userId}`);
        const data = await response.json();
        setUser(data);
        setEditedName(data.userName);
        setEditedEmail(data.email);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchDataFromServer();
  }, []);

  const handleEditIconClick = () => {
    setEditMode(true);
  };

  const handleSaveChanges = async () => {
    try {
      // Update the database with the edited values
      const userId = user.id;
      console.log("Save changes for", userId);

      const response = await fetch(`${laravel.url}updateUserData/${userId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: editedName,
          email: editedEmail,
        }),
      });

      if (response.ok) {
        if (response.status === 200) {
          console.log("Record updated successfully");
          setUser({
            ...user,
            userName: editedName,
            email: editedEmail,
          });
        } else {
          console.log("Record not found or data already up-to-date");
        }
        setEditMode(false);
      } else {
        console.error("Failed to update user data");
      }
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    // Reset editedName and editedEmail to their original values
    setEditedName(user.userName);
    setEditedEmail(user.email);
  };

  return (
    <div>
      <SideNavBar />
      <div style={{ zIndex: "-50" }}>
        <NavbarComponent />
      </div>
      <Container fluid style={{ paddingTop: "10ex" }}>
        <Row
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col
            lg={4}
            className="m-2"
            style={{
              height: "250px",
              borderRadius: "10px",
              padding: "10px",
              color: "white",
              position: "relative",
            }}
          >
            <ListGroup>
              <ListGroupItem
                style={{
                  backgroundColor: "#291749",
                  color: "white",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                My Profile
                <FontAwesomeIcon
                  icon={faEdit}
                  onClick={handleEditIconClick}
                  style={{ cursor: "pointer", marginLeft: "5px" }}
                />
              </ListGroupItem>
              {editMode ? (
                <>
                  <ListGroupItem>
                    Name
                    <Form.Control
                      type="text"
                      placeholder="Enter name"
                      value={editedName}
                      onChange={(e) => setEditedName(e.target.value)}
                    />
                  </ListGroupItem>
                  <ListGroupItem>
                    Email
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      value={editedEmail}
                      onChange={(e) => setEditedEmail(e.target.value)}
                    />
                  </ListGroupItem>
                  <ListGroupItem style={{ textAlign: "center" }}>
                    <Button
                      variant="success"
                      onClick={handleSaveChanges}
                      style={{ marginRight: "5px" }}
                    >
                      Save Changes
                    </Button>
                    <Button variant="danger" onClick={handleCancelEdit}>
                      Cancel
                    </Button>
                  </ListGroupItem>
                </>
              ) : (
                <>
                  <ListGroupItem>User ID: {user.id}</ListGroupItem>
                  <ListGroupItem>Name: {user.userName}</ListGroupItem>
                  <ListGroupItem>Email: {user.email}</ListGroupItem>
                  <ListGroupItem>Role: {user.role}</ListGroupItem>
                </>
              )}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default QaAccount;
