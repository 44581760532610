import { useEffect, useState } from "react";
import { Image, Nav, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logo from "../imgs/logo.jpeg";

const NavbarComponent = () => {
  const navigate = useNavigate();

  const getFromStorage = () => {
    const data = localStorage.getItem("RCUsers");
    if (data !== null) {
      return JSON.parse(data);
    }
    return [];
  };

  const [user, setUser] = useState([]);

  useEffect(() => {
    setUser(getFromStorage());
  }, []);

  const handleNavigation = (path) => {
    window.location.hash = path; // Update the hash portion of the URL
    navigate(path); // Navigate programmatically using React Router's useNavigate
  };

  return (
    <Navbar bg="light" expand="lg" fixed="top" style={{ padding: "10px" }}>
      <Navbar.Brand
        to="/"
        className="flex align-items-center"
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Image
          fluid
          src={logo}
          alt={logo}
          style={{
            width: "60px",
            height: "40px",
            objectFit: "cover",
          }}
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto justify-content-end" style={{ width: "100%" }}>
          <Nav.Link onClick={() => handleNavigation("/")} to="#/">
            Home
          </Nav.Link>
          <Nav.Link
            onClick={() => handleNavigation("/about-us")}
            to="#/about-us"
          >
            About Us
          </Nav.Link>
          <Nav.Link
            onClick={() => handleNavigation("/contact-us")}
            to="#/contact-us"
          >
            Contact Us
          </Nav.Link>
          <Nav.Link to="http://www.hxd9973.uta.cloud/">Blog</Nav.Link>
          {user.length === 0 && (
            <>
              <Nav.Link
                onClick={() => handleNavigation("/register")}
                to="#/register"
              >
                Register
              </Nav.Link>
              <Nav.Link onClick={() => handleNavigation("/login")} to="#/login">
                Login
              </Nav.Link>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
