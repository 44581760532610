import React, { useEffect, useState } from "react";
import { Col, Container, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NavbarComponent from "../components/Navbar";
import SideNavBarCoordinator from "../components/sidebarCordinator";
import laravel from "../layouts/laravel.json";
{
  /* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/
}
const CoordinatorAccount = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({});

  useEffect(() => {
    const fetchDataFromServer = async () => {
      try {
        const userId = JSON.parse(localStorage.getItem("RCUsers")).id;
        const response = await fetch(laravel.url + `getUserById/${userId}`);
        const data = await response.json();
        setUser(data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchDataFromServer();
  }, []);

  return (
    <div>
      <SideNavBarCoordinator />
      <div
        style={{
          zIndex: "-50",
        }}
      >
        <NavbarComponent />
      </div>
      <Container
        fluid
        style={{
          paddingTop: "10ex",
        }}
      >
        <Row
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col
            lg={4}
            className="m-2"
            style={{
              height: "250px",
              borderRadius: "10px",
              padding: "10px",
              color: "white",
              position: "relative",
            }}
          >
            <ListGroup>
              <ListGroupItem
                style={{
                  backgroundColor: "#291749",
                  color: "white",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                My Profile
              </ListGroupItem>
              <ListGroupItem>
                <b>User ID: </b>
                {user.id}
              </ListGroupItem>
              <ListGroupItem>
                <b>Name: </b>
                {user.userName}
              </ListGroupItem>
              <ListGroupItem>
                <b>Email: </b>
                {user.email}
              </ListGroupItem>
              <ListGroupItem>
                <b>Role: </b>
                {user.role}
              </ListGroupItem>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CoordinatorAccount;
