import React, { useState } from 'react';
import { Button, Col, Container, Form, Row, Alert } from 'react-bootstrap';
import Navbar from '../components/Navbar';
import NavbarComponent from '../components/Navbar';
import laravel from "../layouts/laravel.json"
import SideNavBarCoordinator from '../components/sidebarCordinator';
{/* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/}
const containerStyles = {
  backgroundColor: '#fff',
  padding: '10px',
  borderRadius: '5px',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  // height: '450px'
};

const CoordinatorCreateProgram = () => {
  const [courseNumber, setCourseNumber] = useState('');
  const [courseTitle, setCourseTitle] = useState('');
  const [credits, setCredits] = useState('');
  const [courseType, setCourseType] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleRegister = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('courseNumber', courseNumber);
    formData.append('courseTitle', courseTitle);
    formData.append('credits', credits);
    formData.append('courseType', courseType);

    // Make a POST request to your PHP script
    fetch(laravel.url+'createProgram', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        console.log(data.message);
        // Handle the response and update the success message
        if (data.message === 'Data inserted successfully!') {
          setSuccessMessage('Course has been created in the database.');

          // Clear the input fields
          setCourseNumber('');
          setCourseTitle('');
          setCredits('');
          setCourseType('');
        } else {
             // Clear the input fields
          setCourseNumber('');
          setCourseTitle('');
          setCredits('');
          setCourseType('');
          setSuccessMessage('Course has been created in the database.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setSuccessMessage('An error occurred while creating the course.');
      });
  };

  return (
    <>
      <Navbar />
      <NavbarComponent />
      <SideNavBarCoordinator />
      <Container
        fluid
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          marginTop: '2rem',
        }}
      >
        <Row
          xs
          lg={12}
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Col xs={4} lg={4} md={6}>
            <h6  style={{
                padding: '10px',
                background: 'rgb(41, 23, 73)',
                color:'white',
            alignItems: 'center',
          }}>Add information related to the course you want to create:</h6>
            <Form style={containerStyles} onSubmit={handleRegister}>
              <Form.Group className='mb-2 mt-2'>
                <Form.Label>Course Number</Form.Label>
                <Form.Control
                  size='md'
                  type='text'
                  placeholder='Enter Course Number'
                  value={courseNumber}
                  onChange={(e) => setCourseNumber(e.target.value)}
                />
              </Form.Group>

              <Form.Group className='mb-2'>
                <Form.Label>Course Title</Form.Label>
                <Form.Control
                  size='md'
                  type='text'
                  placeholder='Enter Course Title'
                  value={courseTitle}
                  onChange={(e) => setCourseTitle(e.target.value)}
                />
              </Form.Group>

              <Form.Group className='mb-2'>
                <Form.Label>Credits</Form.Label>
                <Form.Control
                  size='md'
                  type='text'
                  placeholder='Enter Credits'
                  value={credits}
                  onChange={(e) => setCredits(e.target.value)}
                />
              </Form.Group>

              <Form.Group className='mb-2'>
                <Form.Label>Course Type</Form.Label>
                <Form.Control
                  size='md'
                  type='text'
                  placeholder='Enter Course Type'
                  value={courseType}
                  onChange={(e) => setCourseType(e.target.value)}
                />
              </Form.Group>

              <Button
                size='md'
                style={{
                  backgroundColor: '#291749',
                  border: 'none',
                  width: '100%',
                }}
                type="submit"
              >
                Create Program
              </Button>
            </Form>
            {/* Display the success message */}
            {successMessage && (
              <Alert variant="success" className="mt-3">
                {successMessage}
              </Alert>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CoordinatorCreateProgram;