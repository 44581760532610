import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { Link } from "react-router-dom"; // Import Link from React Router
import NavbarComponent from "../components/Navbar";
import SideNavBarStudent from "../components/sidebarstu";
import laravel from "../layouts/laravel.json";
{
  /* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/
}
const Student2 = () => {
  const [studentData, setStudentData] = useState({
    name: "",
    email: "",
  });
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    // Fetch student details from local storage
    const storedUserData = localStorage.getItem("RCUsers");
    if (storedUserData) {
      const userData = JSON.parse(storedUserData);
      setStudentData({
        name: userData.userName,
        email: userData.email,
      });

      // Fetch the user's enrolled courses from your server using the user's id
      const userId = userData.id;
      const fetchEnrolledCourses = async () => {
        try {
          const response = await axios.get(
            laravel.url + `getCourses/student_id/${userId}`
          );
          const data = response.data;

          if (!data.error) {
            // Set the enrolled courses
            setCourses(data);
          } else {
            console.error("Error fetching student courses:", data.error);
          }
        } catch (error) {
          console.error("Error fetching student courses:", error);
        }
      };

      fetchEnrolledCourses();
    }
  }, []);

  return (
    <div>
      <SideNavBarStudent />
      <NavbarComponent />
      <Container
        fluid="sm"
        style={{
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Row
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col lg={8} className="mt-5 mb-2">
            <ListGroup>
              <ListGroupItem
                style={{ backgroundColor: "#291749", color: "white" }}
              >
                Student Details
              </ListGroupItem>
              <ListGroupItem>
                <strong>Name:</strong> {studentData.name}
              </ListGroupItem>
              <ListGroupItem>
                <strong>Email:</strong> {studentData.email}
              </ListGroupItem>
            </ListGroup>
            <ListGroup>
              <ListGroupItem
                style={{ backgroundColor: "#291749", color: "white" }}
              >
                Enrolled Courses
              </ListGroupItem>
              {courses && courses.length > 0 ? (
                courses.map((course, index) => (
                  <ListGroupItem key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        {course.courseNumber} - {course.courseTitle}
                      </span>
                      {/* Use Link to navigate to the course-specific page with the associated course ID */}
                      <Link
                        to={`/viewmore/${course.id}`}
                        style={{ marginLeft: "auto" }}
                      >
                        View More
                      </Link>
                    </div>
                  </ListGroupItem>
                ))
              ) : (
                <ListGroupItem>No courses enrolled.</ListGroupItem>
              )}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Student2;
