import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import React from "react";

/* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/

import SideNav, { NavIcon, NavItem, NavText } from "@trendmicro/react-sidenav";
import { Link } from "react-router-dom";

class SideNavBar3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true,
    };
  }

  render() {
    return (
      <SideNav
        expanded={this.state.isVisible}
        style={{
          backgroundColor: "#291749",
          zIndex: "100",
          top: "2.9rem",
          height: "100%",
          position: "fixed",
        }}
      >
        <SideNav.Toggle
          onClick={() => {
            this.setState({ isVisible: !this.state.isVisible });
          }}
        />
        <SideNav.Nav defaultSelected="home">
          <NavItem eventKey="home">
            <NavText style={{ fontSize: "1.5em" }}>ADMIN</NavText>
          </NavItem>
          <NavItem eventKey="home">
            <NavIcon>
              <i className="fa fa-fw fa-home" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>
              <Link to="/admin">Dashboard</Link>
            </NavText>
          </NavItem>

          <NavItem eventKey="account">
            <NavIcon>
              <i className="fa fa-fw fa-user" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>
              <Link to="/adminac">Account</Link>
            </NavText>
          </NavItem>
          <NavItem eventKey="chatting">
            <NavIcon>
              <i className="fa fa-fw fa-comment" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>
              <Link to="/chatting">Chatting</Link>
            </NavText>
          </NavItem>

          <NavItem eventKey="students">
            <NavIcon>
              <i className="fa fa-fw fa-users" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>
              <Link to="/studentadmin">Students</Link>
            </NavText>
          </NavItem>

          <NavItem eventKey="instructor">
            <NavIcon>
              <i className="fa fa-fw fa-users" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>
              <Link to="/adminin">Instructor</Link>
            </NavText>
          </NavItem>

          <NavItem eventKey="coordinator">
            <NavIcon>
              <i className="fa fa-fw fa-user" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>
              <Link to="/cordad">Co-ordinator</Link>
            </NavText>
          </NavItem>

          <NavItem eventKey="coordinator">
            <NavIcon>
              <i className="fa fa-fw fa-user" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>
              <Link to="/officad">QA-Ofiicer</Link>
            </NavText>
          </NavItem>

          <NavItem eventKey="chata">
            <NavIcon>
              <i
                className="fa fa-fw fa-line-chart"
                style={{ fontSize: "1.75em" }}
              />
            </NavIcon>
            <NavText>
              <Link to="/chata">ChatBot</Link>
            </NavText>
          </NavItem>
          <NavItem eventKey="reports">
            <NavIcon>
              <i
                className="fa fa-fw fa-comment"
                style={{ fontSize: "1.75em" }}
              />
            </NavIcon>
            <NavText>
              <Link to="/discussions">Discussion Forum</Link>
            </NavText>
          </NavItem>
          <NavItem eventKey="reports">
            <NavIcon>
              <i
                className="fa fa-fw fa-sign-out"
                style={{ fontSize: "1.75em" }}
              />
            </NavIcon>
            <NavText>
              <Link to="/logout">logout</Link>
            </NavText>
          </NavItem>
        </SideNav.Nav>
      </SideNav>
    );
  }
}

export default SideNavBar3;
