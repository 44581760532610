import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import laravel from "../layouts/laravel.json";

const ChatComponent = () => {
  const navigate = useNavigate();
  const [chats, setChats] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const data = JSON.parse(localStorage.getItem("RCUsers"));
  const [userId, setUserId] = useState(0);

  useEffect(() => {
    if (!!data) {
      setUserId(data.id);
    } else {
      navigate("/");
    }
  }, [data, navigate]);

  useEffect(() => {
    fetchAllChats();
  }, [userId]);

  const fetchAllChats = async () => {
    try {
      const response = await axios.get(laravel.url + "allChats");
      setChats(response.data.data);
    } catch (error) {
      console.error("Error fetching chats:", error);
    }
  };

  const handleAddChat = async () => {
    try {
      await axios.post(laravel.url + "addChat", {
        user_id: userId,
        message: newMessage,
      });

      fetchAllChats();

      setNewMessage("");
    } catch (error) {
      console.error("Error adding chat:", error);
    }
  };

  return (
    <div style={{ width: "600px" }}>
      <h2>Discussion Forum</h2>
      <ListGroup style={{ maxHeight: "400px", overflowY: "auto" }}>
        {chats.map((chat) => (
          <ListGroup.Item
            key={chat.id}
            style={{
              display: "flex",
              justifyContent:
                userId === chat.user_id ? "flex-end" : "flex-start",
            }}
          >
            <span
              className={`badge rounded-pill ${
                userId === chat.user_id ? "bg-success" : "bg-primary"
              }`}
              style={{
                textAlign: userId === chat.user_id ? "end" : "left",
                fontSize: "16px",
              }}
            >
              {userId === chat.user_id ? "You" : chat.user.username}
              <br />
              {chat.message}
            </span>
          </ListGroup.Item>
        ))}
      </ListGroup>

      <div className="mt-3">
        <InputGroup>
          <Form.Control
            type="text"
            placeholder="New Message"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
          />
          <Button variant="primary" onClick={handleAddChat}>
            Add Chat
          </Button>
        </InputGroup>
      </div>
    </div>
  );
};

export default ChatComponent;
