import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import NavbarLogoutComponent from "../components/Navbar_logout";
import SideNavBarCoordinator from "../components/sidebarCordinator";
import laravel from "../layouts/laravel.json";
{
  /* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/
}

const ProgramDetail = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState({}); // Initialize data as an object

  const { id } = useParams();
  const url = laravel.url + `getCourses/${id}`;

  const getProgramDetail = async () => {
    setLoading(true);
    await axios
      .get(url)
      .then((response) => {
        console.log(response.data);
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setError(true);
      });
  };

  useEffect(() => {
    getProgramDetail();
  }, []);

  console.log(data,'Final Data')

  return (
    <div>
      <SideNavBarCoordinator />
      <div
        style={{
          zIndex: "-50",
        }}
      >
        <NavbarLogoutComponent />
      </div>
      <Container
        fluid
        style={{
          paddingTop: "10ex",
        }}
      >
        <Row
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col
            lg={4}
            className="m-2"
            style={{
              height: "250px",
              borderRadius: "10px",
              padding: "10px",
              color: "white",
              position: "relative",
            }}
          >
            <ListGroup>
              <ListGroupItem
                style={{
                  backgroundColor: "#291749",
                  color: "white",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                Course Details
              </ListGroupItem>
              <ListGroupItem>
                <b>Course number: </b>
                {data.courseNumber}
              </ListGroupItem>
              <ListGroupItem>
                <b>Course Title: </b>
                {data.courseTitle}
              </ListGroupItem>
              <ListGroupItem>
                <b>Credits: </b>
                {data.credits}
              </ListGroupItem>
              <ListGroupItem>
                <b>Course Type: </b>
                {data.courseType}
              </ListGroupItem>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProgramDetail;
