import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import React, { Component } from "react";
import { Link } from "react-router-dom";

/* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/

import SideNav, { NavIcon, NavItem, NavText } from "@trendmicro/react-sidenav";

class SideNavStu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true,
    };
  }

  render() {
    return (
      <SideNav
        expanded={this.state.isVisible}
        style={{
          backgroundColor: "#291749",
          zIndex: "100",
          top: "2.9rem",
          height: "100%",
          position: "fixed",
        }}
      >
        <SideNav.Toggle
          onClick={() => {
            this.setState({ isVisible: !this.state.isVisible });
          }}
        />
        <SideNav.Nav defaultSelected="dashboard">
          <NavItem eventKey="dashboard">
            <NavIcon>
              <i className="fa fa-fw fa-home" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>
              <Link to="/Student">Dashboard</Link>
            </NavText>
          </NavItem>

          <NavItem eventKey="account">
            <NavIcon>
              <i className="fa fa-fw fa-user" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>
              <Link to="/student-account">Account</Link>
            </NavText>
          </NavItem>
          <NavItem eventKey="chatting">
            <NavIcon>
              <i className="fa fa-fw fa-comment" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>
              <Link to="/chatting">Chatting</Link>
            </NavText>
          </NavItem>
          <NavItem eventKey="discussionforum">
            <NavIcon>
              <i
                className="fa fa-fw fa-comments"
                style={{ fontSize: "1.75em" }}
              />
            </NavIcon>
            <NavText>
              <Link to="/discussions">Discussion Forum</Link>
            </NavText>
          </NavItem>

          <NavItem eventKey="chatwithus">
            <NavIcon>
              <i
                className="fa fa-fw fa-comment"
                style={{ fontSize: "1.75em" }}
              />
            </NavIcon>
            <NavText>
              <Link to="/chatwithus">Chat with Us</Link>
            </NavText>
          </NavItem>

          <NavItem eventKey="report">
            <NavIcon>
              <i className="fa fa-fw fa-file" style={{ fontSize: "1.75em" }} />
            </NavIcon>
            <NavText>
              <Link to="/report">Report</Link>
            </NavText>
          </NavItem>

          <NavItem eventKey="logout">
            <NavIcon>
              <i
                className="fa fa-fw fa-sign-out"
                style={{ fontSize: "1.75em" }}
              />
            </NavIcon>
            <NavText>
              <Link to="/logout">Logout</Link>
            </NavText>
          </NavItem>
        </SideNav.Nav>
      </SideNav>
    );
  }
}

export default SideNavStu;
