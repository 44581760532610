import React, { Component } from 'react';
import ChatBot  from 'react-simple-chatbot';
import '../css/Chatbot.css'

class MyChatbotCO extends Component {

  render() {
    console.log('MyChatbot component rendered');
    const steps = [{
      id:'Greet',
      message:'Hi, Coordinator How are you?',
      trigger:'inputans'
    },
    {
      id: 'inputans',
      user: true,
      trigger: 'Ask Name'
  },
    {
      id:'Ask Name',
      message: "I'd love to know your name if you don't mind sharing",
      trigger:'waiting1'
    },
  {
    id:'waiting1',
    user:true,
    trigger:'Name'
  },{
    id:'Name',
    message:'Hi {previousValue}, Please Select issue',
    trigger:'1'
  },{
    id:'1',
    message:'Your Issue Realted to?',
    trigger:'2'
  },{
    id:'2',
    options:[
      {value:'create program ',label:'Create  Program',trigger:'3'},
      {value:'view specific user',label:'view user',trigger:'15'},
      {value:'Manging User Account',label:'User Account',trigger:'4'},
      {value:'view queries',label:'View queries',trigger:'5'}
    ]
  },{
    id:'3',
    options:[{
      value:'How do I create a specific program on the Platform',label:'How do create a specific program on the Platform',trigger:'8'
    }]
  },{
    id:'4',
    options:[{
      value:'How do I update my user details?',label:'What tools are available for managing user accounts?',trigger:'9'
    }]
  
  },{
    id:'5',
    options:[{
      value:'How do I view queries?',label:'How do I view queries?',trigger:'10'
    }]
  
  },{
    id:'8',
    message:'Use the ‘Create program fill out mentioned details  ’ feature to create a program.',
    trigger:'13'
  },
  {
    id:'16',
    message:'Use the ‘view program  ’ feature to view all the programs.',
    trigger:'13'
  },
  {
    id:'15',
    options:[{
      value:'How do I view program details?',label:'How do I view program details?',trigger:'16'
    }]
  
  },{
    id:'9',
    message:'The ‘User Account Management’ function allows you to modify..',
    trigger:'13'
  },{
    id:'10',
    message:'Navigate to queries option available on navigate menu to view queries.',
    trigger:'13'
  },{
    id:'13',
  message:'Good to help You',
  end:true
  },];

    return (
      <ChatBot
        steps={steps}
        floating={true} // Adjust based on your UI preference
        opened={false}   // Chatbot is opened by default
      />
    );
  }
}

export default MyChatbotCO;