import { useNavigate } from "react-router-dom";

import laravel from "../layouts/laravel.json";
// import '../css/login.css'
import axios from "axios";
import moment from "moment/moment";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import NavbarComponent from "../components/Navbar";
import SideNavBar3 from "../components/sidebaradmin";
{
  /* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/
}
const containerStyles = {
  backgroundColor: "#fff",
  padding: "100px",
  margin: "20px",
  marginTop: "50px",

  borderRadius: "5px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  // height: '450px'
};

const leftMargin = {
  margin: "0px 0px 0px 5px",
};

const Admin = () => {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState("Coordinator");

  const navigate = useNavigate("");

  const handleRegister = async () => {
    const url = laravel.url + "register";
    const formData = new FormData();
    formData.append("userName", userName);
    formData.append("email", email);
    formData.append("userPassword", password);
    formData.append(
      "createdAt",
      moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
    );
    formData.append("role", role);
    await axios
      .post(url, formData)
      .then((response) => {
        console.log("Role sent to server:", role);
        console.log(response);
      })
      .catch((err) => alert(err.message));
    const credentials = {
      userName,
      email,
      password,
      role,
      logged: true,
    };
  };

  return (
    <div>
      <NavbarComponent />
      {/* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/}
      <SideNavBar3 />
      {/* <NavbarComponent/> */}
      <Row
        xs
        lg={12}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Col xs={4} lg={4} md={6}>
          <Form style={containerStyles}>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <h2
                style={{
                  margin: "10px",
                }}
              >
                Create User
              </h2>
            </div>
            <div
              style={{
                textAlign: "center",
              }}
              className="text-danger text-center"
            >
              {password !== confirmPassword && "Passwords Don not Match!!"}
            </div>

            <Form.Group className="mb-2 mt-2">
              {/* <Form.Label style={leftMargin}>Username</Form.Label> */}
              <Form.Control
                size="md"
                type="text"
                placeholder="Enter Name"
                onChange={(e) => setUserName(e.target.value)}
                name="userName"
                id="userName"
              />
            </Form.Group>
            <Form.Group className="mb-2">
              {/* <Form.Label style={leftMargin}>Email</Form.Label> */}
              <Form.Control
                type="email"
                size="md"
                placeholder="Enter email address"
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                id="email"
              />
            </Form.Group>

            <Form.Group className="mb-2">
              {/* <Form.Label className='font-bold' style={leftMargin}>Password</Form.Label> */}
              <Form.Control
                size="md"
                type="password"
                placeholder="Enter password"
                onChange={(e) => setPassword(e.target.value)}
                name="userPassword"
                id="userPassword"
              />
            </Form.Group>

            <Form.Group className="mb-2">
              {/* <Form.Label style={leftMargin}>Confirm Password</Form.Label> */}
              <Form.Control
                size="md"
                type="password"
                placeholder="Confirm Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>

            {/* choose role */}
            <Form.Group className="mb-2 mt-2">
              <Form.Label style={leftMargin}>Select Role</Form.Label>
              <Form.Select
                name="role"
                id="role"
                value={role}
                onChange={(e) => {
                  setRole(e.target.value);
                  console.log(role);
                }}
              >
                <option value={"Coordinator"}>Program Coordinator</option>
                <option value={"Instructor"}>Instructor</option>
                <option value={"Student"}>Student</option>
                <option value={"qa"}>QA-Officer</option>
              </Form.Select>
            </Form.Group>

            <Button
              size="md"
              style={{
                backgroundColor: "#291749",
                border: "none",
                width: "100%",
              }}
              onClick={() => handleRegister()}
              disabled={password !== confirmPassword}
            >
              Create
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Admin;
