import { useEffect, useState } from "react";
import { Image, Nav, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logo from "../imgs/logo.jpeg";

{
  /* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/
}

const NavbarLogoutComponent = () => {
  const navigate = useNavigate();

  const getFromStorage = () => {
    const data = localStorage.getItem("RCUsers");
    if (data !== null) {
      return JSON.parse(data);
    }
    return [];
  };

  const [user, setUser] = useState([]);
  const logout = () => {
    const data = getFromStorage();
    data.logged = false;
    localStorage.setItem("RCUsers", JSON.stringify(data));
    setUser([]);
  };

  useEffect(() => {
    setUser(getFromStorage());
    // console.log(user,'yuy')
  }, []);

  return (
    <Navbar
      bg="light"
      expand="lg"
      fixed="top"
      style={{
        padding: "10px",
      }}
    >
      <Navbar.Brand
        to="/"
        className="flex align-items-center"
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Image
          fluid
          src={logo}
          alt={logo}
          style={{
            width: "60px",
            height: "40px",
            objectFit: "cover",
          }}
        />
        {/* <div className='text-secondary ml-2' style={{
          marginLeft:"10px",
          textTransform:"uppercase",
          fontWeight:"bold"
        }}>
        Skillify
        </div> */}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto justify-content-end" style={{ width: "100%" }}>
          <Nav.Link to="/">Home</Nav.Link>
          {/* <Nav.Link to="/about-us">About Us</Nav.Link>
          <Nav.Link to="/contact-us">Contact Us</Nav.Link> */}

          {/* <Nav.Link to="/register">Register</Nav.Link> */}
          <Nav.Link to="/login">Logout</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarLogoutComponent;
