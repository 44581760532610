import React from 'react';
import Navbar from '../components/Navbar';
import { Container } from 'react-bootstrap';

const styles = {
  backgroundColor: '#291749',
  marginTop: '5rem',
  color: 'white',
};

// Project Group: 5

// Project Group Members: Vaibhavi Arjunwadkar (1001826818)
//                    Sashidhar Boyapati (1002087319)
//                    Hema Sai Desai (1002029973)
//                    Hemanth Reddy Gillela (1002033611)
//                    Smrithi Gujjula (1002073701) 

const Processes = () => {
  return (
    <>
      <Navbar />
      <Container style={styles}>
        <div className="content-container">
          <h1>PROCESSES</h1>
          <p>
            Establish, implement, and maintain the organization's QMS.
            Ensure compliance with relevant quality standards and regulations.
          </p>
          <p>
            Develop and implement training programs for employees on quality processes.
            Assess and ensure the competence of personnel involved in critical processes.
          </p>
          <p>
            Design and implement assessment tools, exams, and evaluation methods to measure student learning outcomes.
          </p>
          <p>
            Monitor and enhance student support services, including counseling, tutoring, and career guidance.
          </p>
        </div>
      </Container>

      {/* Footer code */}
      <div style={{ textAlign: 'center', fontSize: '10px' }} className="fixed-footer">
        @ 2023 SKILLIFY, INC
      </div>
    </>
  );
};

export default Processes;