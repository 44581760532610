// Project Group: 5

// Project Group Members: Vaibhavi Arjunwadkar (1001826818)
//                    Sashidhar Boyapati (1002087319)
//                    Hema Sai Desai (1002029973)
//                    Hemanth Reddy Gillela (1002033611)
//                    Smrithi Gujjula (1002073701)

import React, { useState } from "react";
import Navbar from "../components/Navbar";
import { Button, Container, Form, Row, Col, Alert } from "react-bootstrap";
import laravel from "../layouts/laravel.json";
import axios from "axios";

const containerStyles = {
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "5px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
};

const Contact = () => {
  const [formDataa, setformDataa] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [showMessage, setShowMessage] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformDataa({ ...formDataa, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = laravel.url + "contactus";
    const formData = new FormData();
    formData.append("name", formDataa.name);
    formData.append("email", formDataa.email);
    formData.append("phone", formDataa.phone);
    formData.append("message", formDataa.message);
    await axios
      .post(url, formDataa)
      .then((response) => {
        console.log(response);
      })
      .catch((err) => alert(err.message));
  };

  return (
    <>
      <Navbar />
      <Container
        fluid
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#291749",
        }}
      >
        <Row
          xs
          lg={12}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col xs={4} lg={4} md={6}>
            <Form style={containerStyles} onSubmit={handleSubmit}>
              <div style={{ textAlign: "center" }}>
                <h3>Contact Us.</h3>
                <p style={{ fontSize: "11px" }}>
                  Let us know your questions, suggestions, and concerns by
                  filling out the contact form below.
                </p>
              </div>
              <Form.Group className="mb-4">
                <Form.Control
                  type="text"
                  size="md"
                  placeholder="Enter name"
                  name="name"
                  value={formDataa.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Control
                  type="email"
                  size="md"
                  placeholder="Enter email address"
                  name="email"
                  value={formDataa.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Control
                  size="md"
                  type="number"
                  placeholder="Enter phone number"
                  name="phone"
                  value={formDataa.phone}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="message"
                  value={formDataa.message}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Button
                type="submit"
                size="md"
                style={{
                  backgroundColor: "#291749",
                  border: "none",
                  width: "100%",
                }}
                className="mb-5"
              >
                Contact Us
              </Button>
            </Form>
            {showMessage && (
              <Alert variant="success" className="mb-4">
                Thank you for contacting us! We will get back to you soon!
              </Alert>
            )}
          </Col>
        </Row>
      </Container>
      <div
        style={{ textAlign: "center", fontSize: "10px" }}
        className="fixed-footer"
      >
        @ 2023 SKILLIFY, INC
      </div>
    </>
  );
};

export default Contact;
