import React from 'react';
import Navbar from '../components/Navbar';
import { Container } from 'react-bootstrap';

const styles = {
  backgroundColor: '#291749',
  marginTop: '5rem',
  color: 'white',
};

const Curriculum = () => {
  return (
    <>
      <Navbar />
      <Container style={styles}>
        <div className="content-container">
          <h1>CURRICULUM</h1>
          <p>
            Our curriculum is designed to provide a comprehensive and
            well-rounded education for our students. We strive to offer a
            curriculum that is both academically rigorous and relevant to
            real-world applications.
          </p>
          <p>
            Key features of our curriculum include:
            <ul>
              <li>Specialized courses in various fields</li>
              <li>Hands-on learning opportunities</li>
              <li>Integration of the latest industry practices</li>
              <li>Flexible course options to meet diverse student needs</li>
            </ul>
          </p>
          <p>
            We continually review and update our curriculum to ensure that it
            aligns with educational objectives and industry best practices. Our
            goal is to prepare students for success in their chosen careers.
          </p>
        </div>
      </Container>

      {/* Footer code */}
      <div style={{ textAlign: 'center', fontSize: '10px' }} className="fixed-footer">
        @ 2023 SKILLIFY, INC
      </div>
    </>
  );
};


export default Curriculum;