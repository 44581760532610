import "@fortawesome/fontawesome-free/css/all.min.css";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  ListGroup,
  ListGroupItem,
  Modal,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import NavbarComponent from "../components/Navbar";
import SideNavBarInst from "../components/sidebarinst";
import laravel from "./laravel.json";

{
  /* 

Project Group: 5

Project Group Members: Vaibhavi Arjunwadkar (1001826818)
                   Sashidhar Boyapati (1002087319)
                   Hema Sai Desai (1002029973)
                   Hemanth Reddy Gillela (1002033611)
                   Smrithi Gujjula (1002073701) 

*/
}

const Instructor = () => {
  const [showExamModal, setShowExamModal] = useState(false);
  const [showAssignmentModal, setShowAssignmentModal] = useState(false);
  const [showCourseContentModal, setShowCourseContentModal] = useState(false);
  const [examData, setExamData] = useState([]);
  const [assignmentData, setAssignmentData] = useState([]);
  const [courseContentData, setCourseContentData] = useState([]);
  const [examDetails, setExamDetails] = useState({
    exam_name: "",
    pdf_file_path: null,
  });
  const [assignmentDetails, setAssignmentDetails] = useState({
    assignmentName: "",
    pdfFile: null,
  });
  const [courseContentDetails, setCourseContentDetails] = useState({
    courseContentName: "",
    pdfFile: null,
  });

  const handleShowExamModal = () => setShowExamModal(true);
  const handleCloseExamModal = () => setShowExamModal(false);

  const handleShowAssignmentModal = () => setShowAssignmentModal(true);
  const handleCloseAssignmentModal = () => setShowAssignmentModal(false);

  const handleShowCourseContentModal = () => setShowCourseContentModal(true);
  const handleCloseCourseContentModal = () => setShowCourseContentModal(false);

  const handleExamNameChange = (event) => {
    setExamDetails({ ...examDetails, exam_name: event.target.value });
  };

  const handlePdfFileChange = (event) => {
    setExamDetails({ ...examDetails, pdfFile: event.target.files[0] });
  };

  const handleAssignmentNameChange = (event) => {
    setAssignmentDetails({
      ...assignmentDetails,
      assignmentName: event.target.value,
    });
  };

  const handleAssignmentPdfFileChange = (event) => {
    setAssignmentDetails({
      ...assignmentDetails,
      pdfFile: event.target.files[0],
    });
  };

  const handleCourseContentNameChange = (event) => {
    setCourseContentDetails({
      ...courseContentDetails,
      courseContentName: event.target.value,
    });
  };

  const handleCourseContentPdfFileChange = (event) => {
    setCourseContentDetails({
      ...courseContentDetails,
      pdfFile: event.target.files[0],
    });
  };

  const fetchDataFromServer = async (type) => {
    try {
      const response = await fetch(laravel.url + "fetchData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ type: type }),
      });

      const data = await response.json();

      if (type === "exams") {
        setExamData(data);
      } else if (type === "assignments") {
        setAssignmentData(data);
      } else if (type === "courseContent") {
        setCourseContentData(data);
      }

      console.log(data);
    } catch (error) {
      console.error(`Error fetching ${type} data:`, error);
    }
  };

  const handleSaveExam = async () => {
    try {
      console.log("handleSaveExam");
      const formData = new FormData();
      formData.append("type", "exam");
      formData.append("name", examDetails.exam_name);
      formData.append("pdfFile", examDetails.pdfFile);
      formData.append(
        "particularid",
        JSON.parse(localStorage.getItem("selectedCourse")).id
      );
      const response = await fetch(laravel.url + "saveData", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        console.log("Exam data saved successfully");
        handleCloseExamModal();
        fetchDataFromServer("exams"); // Fetch updated data after saving
      } else {
        console.error("Failed to save exam data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSaveAssignment = async () => {
    try {
      const formData = new FormData();
      formData.append("type", "assignment");
      formData.append("name", assignmentDetails.assignmentName);
      formData.append("pdfFile", assignmentDetails.pdfFile);
      formData.append(
        "particularid",
        JSON.parse(localStorage.getItem("selectedCourse")).id
      );

      const response = await fetch(laravel.url + "saveData", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        console.log("Assignment data saved successfully");
        handleCloseAssignmentModal();
        fetchDataFromServer("assignments"); // Fetch updated data after saving assignments
      } else {
        console.error("Failed to save assignment data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSaveCourseContent = async () => {
    try {
      const formData = new FormData();
      formData.append("type", "courseContent");
      formData.append("name", courseContentDetails.courseContentName);
      formData.append("pdfFile", courseContentDetails.pdfFile);
      formData.append(
        "particularid",
        JSON.parse(localStorage.getItem("selectedCourse")).id
      );
      const response = await fetch(laravel.url + "saveData", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        console.log("Course content data saved successfully");
        handleCloseCourseContentModal();
        fetchDataFromServer("courseContent"); // Fetch updated data after saving
      } else {
        console.error("Failed to save course content data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDelete = async (type, id) => {
    try {
      console.log(id);

      const response = await fetch(laravel.url + `deleteData/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ type: type }),
      });

      if (response.ok) {
        fetchDataFromServer(type); // Fetch updated data after deletion
      } else {
        console.error(`Failed to delete ${type}`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchDataFromServer("exams");
    fetchDataFromServer("assignments");
    fetchDataFromServer("courseContent");
  }, []);

  return (
    <div>
      <SideNavBarInst />
      <NavbarComponent />
      <Container
        fluid="sm"
        style={{
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Row
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col lg={8} className="mt-5 mb-2">
            <ListGroup>
              <ListGroupItem
                style={{ backgroundColor: "#291749", color: "white" }}
              >
                Exams
              </ListGroupItem>
              <Button
                variant="success"
                className="mt-2"
                onClick={handleShowExamModal}
              >
                Create New Exam
              </Button>
              {examData.data ? (
                examData.data.length > 0 ? (
                  examData.data.map((exam, index) => (
                    <ListGroupItem
                      key={index}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ flex: 1 }}>{exam.exam_name}</span>
                      <Link
                        to={`${laravel.files + exam.pdf_file_path}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() =>
                          console.log(
                            "File Path:",
                            `${laravel.files + exam.pdf_file_path}`
                          )
                        }
                        download
                      >
                        <i className="fas fa-file-pdf"></i>
                      </Link>
                      <i
                        className="fas fa-trash-alt"
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                          color: "red",
                        }}
                        onClick={() => handleDelete("exams", exam.id)}
                      ></i>
                    </ListGroupItem>
                  ))
                ) : (
                  <ListGroupItem>No exams available.</ListGroupItem>
                )
              ) : (
                <ListGroupItem>Loading exams...</ListGroupItem>
              )}
            </ListGroup>
          </Col>
          <Col lg={8} className="mt-5 mb-2">
            <ListGroup>
              <ListGroupItem
                style={{ backgroundColor: "#291749", color: "white" }}
              >
                Assignments
              </ListGroupItem>
              <Button
                variant="success"
                className="mt-2"
                onClick={handleShowAssignmentModal}
              >
                Create New Assignment
              </Button>
              {assignmentData.data ? (
                assignmentData.data.length > 0 ? (
                  assignmentData.data.map((assignment, index) => (
                    <ListGroupItem
                      key={index}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ flex: 1 }}>
                        {assignment.assignment_name}
                      </span>
                      <Link
                        to={assignment.pdf_file_path}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        <i className="fas fa-file-pdf"></i>
                      </Link>
                      <i
                        className="fas fa-trash-alt"
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                          color: "red",
                        }}
                        onClick={() =>
                          handleDelete("assignments", assignment.id)
                        }
                      ></i>
                    </ListGroupItem>
                  ))
                ) : (
                  <ListGroupItem>No assignments available.</ListGroupItem>
                )
              ) : (
                <ListGroupItem>Loading assignments...</ListGroupItem>
              )}
            </ListGroup>
          </Col>
          <Col lg={8} className="mt-5 mb-2">
            <ListGroup>
              <ListGroupItem
                style={{ backgroundColor: "#291749", color: "white" }}
              >
                Course Content
              </ListGroupItem>
              <Button
                variant="success"
                className="mt-2"
                onClick={handleShowCourseContentModal}
              >
                Create New Course Content
              </Button>
              {courseContentData.data ? (
                courseContentData.data.length > 0 ? (
                  courseContentData.data.map((courseContent, index) => (
                    <ListGroupItem
                      key={index}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ flex: 1 }}>
                        {courseContent.coursecontent_name}
                      </span>
                      <Link
                        to={courseContent.pdf_file_path}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        <i className="fas fa-file-pdf"></i>
                      </Link>
                      <i
                        className="fas fa-trash-alt"
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                          color: "red",
                        }}
                        onClick={() =>
                          handleDelete("courseContent", courseContent.id)
                        }
                      ></i>
                    </ListGroupItem>
                  ))
                ) : (
                  <ListGroupItem>No course content available.</ListGroupItem>
                )
              ) : (
                <ListGroupItem>Loading course content...</ListGroupItem>
              )}
            </ListGroup>
          </Col>
        </Row>
      </Container>
      <Modal show={showExamModal} onHide={handleCloseExamModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Exam</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="examName">
              <Form.Label>Exam Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter exam name"
                value={examDetails.exam_name}
                onChange={handleExamNameChange}
              />
            </Form.Group>
            <Form.Group controlId="pdfFile">
              <Form.Label>Upload PDF File</Form.Label>
              <Form.Control type="file" onChange={handlePdfFileChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseExamModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveExam}>
            Save Exam
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAssignmentModal} onHide={handleCloseAssignmentModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Assignment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="assignmentName">
              <Form.Label>Assignment Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter assignment name"
                value={assignmentDetails.assignmentName}
                onChange={handleAssignmentNameChange}
              />
            </Form.Group>
            <Form.Group controlId="pdfFile">
              <Form.Label>Upload PDF File</Form.Label>
              <Form.Control
                type="file"
                onChange={handleAssignmentPdfFileChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAssignmentModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveAssignment}>
            Save Assignment
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showCourseContentModal}
        onHide={handleCloseCourseContentModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New Course Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="courseContentName">
              <Form.Label>Course Content Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter course content name"
                value={courseContentDetails.courseContentName}
                onChange={handleCourseContentNameChange}
              />
            </Form.Group>
            <Form.Group controlId="pdfFile">
              <Form.Label>Upload PDF File</Form.Label>
              <Form.Control
                type="file"
                onChange={handleCourseContentPdfFileChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCourseContentModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveCourseContent}>
            Save Course Content
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Instructor;
