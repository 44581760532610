import React, { Component } from 'react';
import ChatBot  from 'react-simple-chatbot';
import '../css/Chatbot.css'
import SideNavBarStudent from "../components/sidebarstu";
class MyChatbotStudent extends Component {

  render() {
    console.log('MyChatbot component rendered');
    const steps = [
    {
      id:'Greet',
      message:'Hi, Student How are you?',
      trigger:'inputans'
    },
    {
        id: 'inputans',
        user: true,
        trigger: 'Ask Name'
    },
    {
      id:'Ask Name',
      message:" I'd love to know your name if you don't mind sharing'",
      trigger:'waiting1'
    },
  {
    id:'waiting1',
    user:true,
    trigger:'Name'
  },{
    id:'Name',
    message:'Hi {previousValue}, Please Select issue',
    trigger:'1'
  },{
    id:'1',
    message:'Your Issue Realted to?',
    trigger:'2'
  },{
    id:'2',
    options:[
      {value:'Course material',label:'Course',trigger:'3'},
      {value:'exam details',label:'Programs',trigger:'3'},
      {value:'Manging User Account',label:'User Account',trigger:'4'},
      {value:'Reports & Analytics',label:'Reports & Analytics',trigger:'5'}
    ]
  },{
    id:'3',
    options:[{
      value:'How do I view Course material on the Platform',label:'How do I view Courses and Programs on the Platform',trigger:'8'
    }]
  },{
    id:'4',
    options:[{
      value:'How do I update my user details?',label:'What tools are available for managing user accounts?',trigger:'9'
    }]
  
  },{
    id:'5',
    options:[{
      value:'How do I generate reports and analytics?',label:'How do I generate reports and analytics?',trigger:'10'
    }]
  
  },{
    id:'8',
    message:'Use the ‘view more option of respective Course ’ feature to oversee course offerings.',
    trigger:'13'
  },{
    id:'9',
    message:'The ‘User Account Management’ function allows you to modify..',
    trigger:'13'
  },{
    id:'10',
    message:'Reports and analytics on student performance, course enrollment, and program effectiveness can be generated through the dedicated analytics section.',
    trigger:'13'
  },{
    id:'13',
  message:'Good to help You',
  end:true
  },];

    return (
    <div>
        <SideNavBarStudent />
      <ChatBot
        steps={steps}
        floating={true} // Adjust based on your UI preference
        opened={false}   // Chatbot is opened by default
      />
      </div>
    );
  }
}

export default MyChatbotStudent;