import React from "react";
import { Navigate, useLocation } from "react-router-dom";

function RestrictedRoutes({ element }) {
  const getFromStorage = () => {
    const data = localStorage.getItem("RCUsers");
    if (data) return JSON.parse(data).role;
    return "Not Logged In";
  };
  const role = getFromStorage();

  const StudentRoutes = [
    "student-account",
    "student",
    "discussionforum",
    "chatwithus",
    "report",
    "viewmore",
    "student-detail",
  ];
  const InstructorRoutes = [
    "instructor",
    "instructors",
    "instructor-account",
    "discussionins",
    "chatI",
  ];
  const QaRoutes = [
    "qa-officer",
    "Curriculum",
    "policies",
    "processes",
    "queries_courses",
    "qa-account",
    "qaStudents",
    "qaAdmins",
    "qaInstructors",
    "qaCoordinators",
    "discussionins",
    
  ];
  const CoordinatorRoutes = [
    "coordinator",
    "coordinator-account",
    "queries",
    "courses",
    "discussions",
    "program-detail",
    "create-program",
    "chatco"
  ];
  const AdminRoutes = [
    "admin",
    "adminac",
    "studentadmin",
    "edit",
    "adminin",
    "cordad",
    "officad",
    "chata"
  ];

  const Anything = [
    "login",
    "/",
    "register",
    "discussions",
    "logout",
    "about-us",
    "contact-us",
    "chatting"
  ];

  const location = useLocation();
  console.log(
    location.pathname.substring(1) === "/"
      ? location.pathname.substring(0)
      : location.pathname.substring(1).split("/")[0],
    "Location"
  );
  const currentRoute =
    location.pathname.substring(0) === "/"
      ? location.pathname.substring(0)
      : location.pathname.substring(1).split("/")[0];

  let allowedRoutes = [];

  switch (role) {
    case "Student":
      allowedRoutes = StudentRoutes;
      break;
    case "Admin":
      allowedRoutes = AdminRoutes;
      break;
    case "qa":
      allowedRoutes = QaRoutes;
      break;
    case "Instructor":
      allowedRoutes = InstructorRoutes;
      break;
    case "Coordinator":
      allowedRoutes = CoordinatorRoutes;
      break;
    default:
      allowedRoutes = Anything;
      break;
  }

  console.log(
    allowedRoutes,
    role,
    currentRoute,
    allowedRoutes.includes(currentRoute)
  );

  const isRouteAllowed = allowedRoutes.some(
    (route) => route.toLowerCase() === currentRoute.toLowerCase()
  );

  return isRouteAllowed || Anything.includes(currentRoute) ? (
    element
  ) : (
    <Navigate to="/not-found" />
  );
}

export default RestrictedRoutes;
