import React, { useState, useEffect } from 'react';
import axios from 'axios';
import laravel from '../layouts/laravel.json';
import '../css/Chatting.css'
const Chatting = ({ user, onClose }) => {
    
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [myEmail, setMyEmail] = useState(null);

    useEffect(() => {
        fetchMessages();
    //  const intervalId = setInterval(fetchMessages, 5000);

    // // Clean up the interval when the component unmounts
    // return () => clearInterval(intervalId);
        setMyEmail(JSON.parse(localStorage.getItem('RCUsers')));
      }, [user]);

    const fetchMessages = async () => {
        try {
            const myEmail = JSON.parse(localStorage.getItem('RCUsers'));
            const response = await axios.get(
                `${laravel.url}messages/${user.email}/${myEmail.email}`
            );
            const reversedResponse = await axios.get(
                `${laravel.url}messages/${myEmail.email}/${user.email}`
            );
            setMessages(response.data.data);
            console.log(response)
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    const handleSendMessage = async () => {
        try {
            const timestamp = new Date().toISOString();
            const myEmail = JSON.parse(localStorage.getItem('RCUsers'));
            // setMessages([...messages, { text: newMessage, sender: 'user' }]);
            // setNewMessage('');
            const response = await axios.post(`${laravel.url}messages/store`, {
                sender_email: myEmail.email,
                receiver_email: user.email,
                message: newMessage,
                created_at: timestamp,
            });

            // Optional: Fetch messages after sending a new message
            fetchMessages();
            // setMessages((prevMessages) => [...prevMessages, response.data.data]);

             // Clear the input field
            setNewMessage('');
           
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    return (
        <div className="chat-container">
           <div className="chat-header">
            <h6>Chatting with {user.userName}</h6>
            <h6>Email {user.email}</h6>
        </div>
        <button onClick={onClose} className="close-button">
            Close
        </button>
            <div className="chat-messages">
                {messages &&
                    messages.map((message, index) => (
                        <div
                            key={index}
                            className={
                                message.sender_email === myEmail?.email
                                    ? 'user-message'
                                    : 'other-message'
                            }
                        >
                            <span className="message-sender">
                                {message.sender_email === myEmail?.email ? 'You' : user.userName}
                            </span>
                            <p className="message-text">{message.message}</p>
                            {/* <p className="message-time">{message.created_at}</p> */}
                        </div>
                    ))}
            </div>
            <div className="chat-input">
                <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type a message..."
                />
                <button onClick={handleSendMessage}>Send</button>
            </div>
        </div>
    );
};

export default Chatting;
